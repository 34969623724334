<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
       <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
    <b-card title="Cities">
      <b-row>
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                @change="sortingTable"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                @change="sortingTable"
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                @change="sortingTable"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="fetchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height:2.142rem; line-height:0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>
        <b-col md="2" class="my-1">
          <!-- add new city button -->
          <!-- add new city button -->
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              style="height:2.142rem; line-height:0.5"
              v-if="authPermissions.includes('create')"
              variant="primary"
              :to="{ path: '/Add/City' }"
            >
              <span class="text-nowrap">Add City</span>
            </b-button>
          </div>
        </b-col>
        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="tagTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :per-page="perPage"
            @sort-changed="fetchData"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
          
            <!-- actions col -->
            <template #cell(actions)="items">
              <!-- delete button category -->
              <b-button
                v-b-modal.modal-restore
                style="padding:7px; margin:6px; width:35px; height:35px;"
                class="btn-icon rounded-circle"
                v-if="authPermissions.includes('update')"
                v-b-popover.hover.top="'Delete Attribute'"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                type="submit"
                @click="clearData(items.item.id)"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  style="width:18px; height:18px;"
                />
              </b-button>
            </template>
          </b-table>
          <b-modal
            id="modal-restore"
            cancel-variant="outline-secondary"
            @ok="restoreData()"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Restore Brand"
          >
            <b-form
              style="max-height:500px; overflow-x:hidden; overflow-y:auto"
            >
              <div v-for="(item, index) in options2" :key="index">
                <b-form-checkbox
                  :id="'checkbox-' + index"
                  v-model="selected[index].area_id"
                  :name="'checkbox-' + index"
                  :value="item.value"
                  :unchecked-value="null"
                >
                  {{ item.text }} </b-form-checkbox
                ><br />
                <div
                  class="ml-3"
                  v-for="(ite, ind) in item.couriers"
                  :key="ind"
                >
                  <b-form-checkbox
                    :id="'checkbox-' + index + ind"
                    v-model="selected[index].couriers[ind]"
                    :name="'checkbox-' + index + ind"
                    :value="ite.value"
                    :unchecked-value="null"
                  >
                    {{ ite.text }} </b-form-checkbox
                  ><br />
                </div>
              </div>
              <infinite-loading
                @infinite="getAllAreasForCity"
              ></infinite-loading>
            </b-form>
          </b-modal>
        </b-col>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              @change="fetchData"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
          <!-- pagination -->
          <div>
            <b-pagination
              @input="fetchData()"
              aria-controls="CategoryTable"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              class="my-0"
            />
          </div>
        </b-card-body>
      </b-row>
    </b-card>
  </div>
</template>

<script>

export default {
  
  data() {
    return {
      peauthPermissionsr: null,
      deactive: false,
      cityID: null,
      deletedCity: false,
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: "created_at",
      sortDesc: true,
      sortDirection: "desc",
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Id",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "code",
          label: "code",
        },
        {
          key: "created_at",
          label: "Created_At",
          sortable: true,
        },
        {
          key: "deleted_at",
          label: "deleted_at",
          sortable: true,
        },
        {
          key: "actions",
        },
      ],
      items: [],
      exportItems: [],
      currentPage2: 0,
      selected: [],
      selectedTwo: {
        areas: [],
      },
      options2: [],
    };
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    // request tags index
    this.fetchData();
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortDirection: function() {
      this.sortingTable();
    },
    sortBy: function() {
      this.sortingTable();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      var url = null;
      if (this.filter == "") {
        url =
          "cities-pagi/trashed/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "cities-pagi/trashed/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
          this.items = result.data.data.data;
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    sortingTable() {
      this.currentPage = 1;
      if (this.sortDesc == true) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
      var url = null;
      if (this.filter == "") {
        url =
          "cities-pagi/trashed/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "cities-pagi/trashed/" +
          this.perPage.toString() +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;
         this.items = result.data.data.data;
          this.exportItems = this.items;
          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    clearData(id) {
      this.cityID = id;
      this.currentPage2 = 0;
      this.options2 = [];
    },
    getAllAreasForCity($state) {
      this.currentPage2 += 1;
      // this.options2.length = 0
      axios
        .get(
          "cities/" +
            this.cityID +
            "/areas-pagi/trashed/50?page=" +
            this.currentPage2
        )
        .then((result) => {
          const areas = result.data.data.data;
          this.loadMore = false;
          if (areas.length > 0) {
            for (let index in areas) {
              this.selected.push({
                area_id: null,
                couriers: [],
              });
              this.options2.push({
                text: areas[index].name,
                value: areas[index].id.toString(),
                couriers: [],
              });
              for (let ind in areas[index].couriers) {
                this.selected[index].couriers.push(null);
                this.options2[index].couriers.push({
                  text: areas[index].couriers[ind].translations.name,
                  value: areas[index].couriers[ind].id.toString(),
                });
              }
            }
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    // deleted function to delete tag
    // delete function to delete city
    restoreData(id) {
      this.selectedTwo.areas.length = 0;
      var contu = true;
      for (let index in this.selected) {
        if (
          this.selected[index].area_id != null &&
          !this.selected[index].couriers.every((element) => element === null)
        ) {
          this.selectedTwo.areas.push({
            area_id: this.selected[index].area_id,
            couriers: [],
          });

          for (let cour in this.selected[index].couriers) {
            if (this.selected[index].couriers[cour] != null) {
              this.selectedTwo.areas[
                this.selectedTwo.areas.length - 1
              ].couriers.push(this.selected[index].couriers[cour]);
            }
          }
        } else if (
          (this.selected[index].area_id != null &&
            this.selected[index].couriers.every(
              (element) => element === null
            )) ||
          (this.selected[index].area_id == null &&
            !this.selected[index].couriers.every((element) => element === null))
        ) {
          this.$bvModal.msgBoxConfirm(
            " Wrong, Must be select an area and then select area's couriers, please try again.",
            {
              title: "Are You Sure?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Ok",
              hideHeaderClose: false,
              centered: true,
            }
          );
          contu = false;
          break;
        } else {
        }
      }

      
      if (contu == true) {
        this.$bvModal
          .msgBoxConfirm("Please confirm that you want to restore city.", {
            title: "Are You Sure?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              axios
                .post(
                  "cities/" + this.cityID + "/restore-city",
                  this.selectedTwo
                )
                .then((result) => {
                  this.$swal({
                    position: "center",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.fetchData();
                })
                .catch((err) => {
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: "Error!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.errors_back.length = 0;
                  if (err.response.data.data != null) {
                    if (this.isString(err.response.data.data)) {
                      this.errors_back.push({
                        error: err.response.data.data,
                      });
                    } else {
                      this.errors_back = err.response.data.data;
                    }
                    this.showDismissibleAlert = true;
                  } else {
                    this.errors_back = [];
                    this.errors_back.push({
                      error: "internal server error",
                    });
                    this.showDismissibleAlert = true;
                  }
                });
            }
          });
      }
    },
    genPDF() {
      const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].city,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [["ID", "Name", "City", "CREATED_AT", "UPDATED_AT"]],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
